export class NavData {
    idOpcion?: number;
    idPadre?: number;
    nOrden?: number;
    name?: string;
    url?: string;
    icon?: string;
    title?: boolean;
    class?: string;
    children?: NavData[];
}