import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NavData } from 'src/app/_model/menu';
import { MenuService } from 'src/app/_service/menu.service';
import { LoginService } from 'src/app/_service/login.service';
import { SharedService } from 'src/app/_service/shared.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  nameuser: string = "";
  fecha: Date;
  perfiluser:string="";
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public asideMenu: Boolean = false;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private _SharedService: SharedService,
    private modalService: NgbModal, @Inject(DOCUMENT) _document?: any,) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }
  navItems: NavData[] = [];
  arraymenu = []
  navData: NavData
  ngOnInit() {

    this.loginService.currentUserSubject.subscribe(response => { 
      if (response['status'] === 200) {
        if (response['data']) {
          this.arraymenu = response['data'].userAccess
          this.perfiluser = this.arraymenu[0].perfil          
          this.nameuser = this.arraymenu[0].usuario
          this.fecha = this.arraymenu[0].fecha
          this.navItems = this.arraymenu[0].accesos

        }
      }
    });

    //  this.navItems = this._SharedService.getMenu();

  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  mostrarAsideMenu(): void {
    this.asideMenu = !this.asideMenu;
  }

  logout() {
    // this.loginService.Logout();
    this.router.navigate(['/login']);
  }

  // GetNameUser() {
  //   this._user = JSON.parse(sessionStorage.getItem('user')) as Auth;
  //   this.nameuser = this._user.unique_name;
  // }

}
