import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DependenceModule } from './dependence/dependence.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DefaultLayoutComponent } from './containers';
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';
import { LoginComponent } from './login/login.component';
import { AlertModule } from './dependence/_alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ServerErrorsInterceptor } from './_interceptor/server-errors.interceptor';
import { Error401LayoutComponent } from './containers/error401-layout/error401-layout.component';
import { Error403LayoutComponent } from './containers/error403-layout/error403-layout.component';
import { Error404LayoutComponent } from './containers/error404-layout/error404-layout.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { OnlyNumber } from './_directive/onlynumber.directive';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
const APP_CONTAINERS = [
  DefaultLayoutComponent,
];

@NgModule({
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    Error401LayoutComponent,
    Error403LayoutComponent,
    Error404LayoutComponent,
    OnlyNumber
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    DependenceModule,
    PerfectScrollbarModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AlertModule,
    ModalModule.forRoot(),
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: ServerErrorsInterceptor,
    multi: true,
  }, {
    provide: LocationStrategy, useClass: HashLocationStrategy
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
