import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error401-layout',
  templateUrl: './error401-layout.component.html',
  styleUrls: ['./error401-layout.component.scss']
})
export class Error401LayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
