import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { MenuService } from '../_service/menu.service';
import { LoginService } from '../_service/login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: LoginService,
        private menuService: MenuService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const helper = new JwtHelperService();
        const currentUser = this.authenticationService.currentUserValue;
        return
        if (currentUser) {
            // this.router.navigate(['not-401']);
            return true;
        } else {
            let access_token = JSON.parse(sessionStorage.getItem(environment.TOKEN_NAME));
            //si no esta expirado
            if (!helper.isTokenExpired(access_token)) {
                // compare si /consulta puede ser accededido por el rol USRSTS01
                const decodedToken = helper.decodeToken(access_token);
                return true
            } else {
                //si el token ya expiro
                sessionStorage.clear();
                this.router.navigate(['/login']);
                return false;
            }
        }


    }

}
