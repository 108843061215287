import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NavData } from '../_model/menu';
import { LoginService } from '../_service/login.service';
import { MenuService } from '../_service/menu.service';
import { SharedService } from '../_service/shared.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  user_name: string = "";
  user_pwd: string = "";
  // NavData: NavData[];
  constructor(private router: Router,
    private _SharedService: SharedService, public toastr: ToastrManager, private loginService: LoginService,
    private menuService: MenuService
  ) { }
  navData: NavData
  ngOnInit() {
    this.navData = new NavData()
  }

  lstMenu = []
  arraymenu = []

  iniciarSesion() {
    let lsError = 0;
    if (this.user_name === '' || !this.user_pwd) {
      this.toastr.infoToastr('Debe ingresar credenciales', 'AVISO!');
      lsError = 1;
    }

    if (lsError === 0) {
      this.loginService.login(this.user_name, this.user_pwd).subscribe(
        response => {
          if (response && response.status === 200) {
            this.arraymenu = response.data.userAccess;
            if (this.arraymenu.length > 0) {
              this.router.navigate(['/home']);
            } else {
              this.toastr.infoToastr('No hay acceso disponible', 'INFO!');
            }
          } else {
            this.toastr.infoToastr('Usuario o contraseña inválidos', 'INFO!');
          }
        },
        error => {
          console.error('Error en la solicitud de login:', error);
          this.toastr.infoToastr('Error en la solicitud de login', 'ERROR');
        }
      );
    }
    // let lsError = 0;
    // if (this.user_name === '' || !this.user_pwd) {
    //   this.toastr.infoToastr('Debe ingresar credenciales', 'AVISO!');
    //   lsError = 1;
    // }

    // if (lsError === 0) {
    //   this.loginService.login(this.user_name, this.user_pwd).subscribe(response => {
    //     console.log(response);
    //     if (response['status'] === 200) {
    //       this.arraymenu = response['data'].userAccess;
    //       if (this.arraymenu.length > 0) {
    //         this.router.navigate(['/home']);
    //       } else {
    //         this.toastr.errorToastr('No hay acceso disponible', 'INFO!');
    //       }
    //     } else {
    //       this.toastr.errorToastr('Usuario o contraseña inválidos', 'INFO!');
    //     }
    //   });
    // }
  }
}
