import { Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  public lstresult: Array<any>;
  setArray(array: any) {
    this.lstresult = array;
  }
  getArray() {
    return this.lstresult;
  }

  public menu: Array<any>;
  setMenu(array: any) {
    this.menu = array;
  }
  getMenu() {
    return this.menu;
  }
 getIdEmpresa(){
  let idEmpresa = JSON.parse(sessionStorage.getItem(environment.idEmpresa)); 
  return idEmpresa
 }
}

 
