import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './login/login.component';
import { Error401LayoutComponent } from './containers/error401-layout/error401-layout.component';
import { Error404LayoutComponent } from './containers/error404-layout/error404-layout.component';
import { AuthGuard } from './_interceptor/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },

  {
    path: 'home',
    component: DefaultLayoutComponent,
      // canActivate: [AuthGuard],
    children: [
      {
        path: 'master-table',
        loadChildren: () => import('./views/configuration/master-table/master-table.module').then(m => m.MasterTableModule),
        //canActivate: [AuthGuard],
      },
      {
        path: 'material',
        loadChildren: () => import('./views/configuration/material/material.module').then(m => m.MaterialModule),
        //canActivate: [AuthGuard]
      },
      {
        path: 'categoria',
        loadChildren: () => import('./views/configuration/category/category.module').then(m => m.CategoryModule),
        //canActivate: [AuthGuard]
      },
      {
        path: 'tag',
        loadChildren: () => import('./views/configuration/tag/tag.module').then(m => m.TagModule),
        //canActivate: [AuthGuard]
      },
      {
        path: 'accesorio',
        loadChildren: () => import('./views/accesorio/accesorio.module').then(m => m.AccesorioModule),
        //canActivate: [AuthGuard]
      },
      {
        path: 'categoriaprecio',
        loadChildren: () => import('./views/configuration/categoria-precio/categoria-precio.module').then(m => m.CategoriaPrecioModule),
        //canActivate: [AuthGuard]
      },

      {
        path: 'genero',
        loadChildren: () => import('./views/configuration/genero/genero.module').then(m => m.GeneroModule),
       // canActivate: [AuthGuard]
      },
      {
        path: 'etiqueta',
        loadChildren: () => import('./views/configuration/etiqueta/etiqueta.module').then(m => m.EtiquetaModule),
       // canActivate: [AuthGuard]
      },
      {
        path: 'color',
        loadChildren: () => import('./views/configuration/color/color.module').then(m => m.ColorModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'formularioproducto',
        loadChildren: () => import('./views/product-form/product-form.module').then(m => m.ProductFormModule),
      //  canActivate: [AuthGuard],
      },
      {
        path: 'marca',
        loadChildren: () => import('./views/marca/marca.module').then(m => m.MarcaModule),
       // canActivate: [AuthGuard],
      },
      {
        path: 'slider',
        loadChildren: () => import('./views/slider/slider.module').then(m => m.SliderModule),
        //canActivate: [AuthGuard],
      },
      {
        path: 'publicidad',
        loadChildren: () => import('./views/publicidad/slider.module').then(m => m.SliderModule),
        //canActivate: [AuthGuard],
      },
      {
        path: 'grupo',
        loadChildren: () => import('./views/configuration/grupo/grupo.module').then(m => m.GrupoModule),
        //canActivate: [AuthGuard],
      },
      {
        path: 'envio',
        loadChildren: () => import('./views/configuration/envio/envio.module').then(m => m.EnvioModule),
        //canActivate: [AuthGuard],
      },
      {
        path: 'publicarproducto',
        loadChildren: () => import('./views/producto/producto.module').then(m => m.ProductoModule),
        //canActivate: [AuthGuard],
      },
      {
        path: 'rangoprecio',
        loadChildren: () => import('./views/configuration/rango-precio/rango-precio.module').then(m => m.RangoPrecioModule),
        //canActivate: [AuthGuard],
      },


    ]

  },
  { path: 'error-401', component: Error401LayoutComponent },
  { path: 'error-403', component: Error401LayoutComponent },
  { path: '**', component: Error401LayoutComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
