import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NavData } from '../_model/menu';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  _URLApiBase: string = `${environment.Url}`;
  constructor(private http: HttpClient) { }
  menuCambio = new Subject<NavData[]>();
  
  listarPorUsuario(nombre: string) {
    return this.http.post<NavData[]>(`${this._URLApiBase}`, nombre);
  }

}
