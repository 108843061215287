import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { tap, catchError, retry } from 'rxjs/operators';
import { LoginService } from '../_service/login.service';
 
@Injectable({
    providedIn: 'root'
})
export class ServerErrorsInterceptor implements HttpInterceptor {

    constructor(private toastr: ToastrManager, private authenticationService: LoginService,) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser = this.authenticationService.currentUserValue;
        let token
        if(currentUser){
            if (currentUser['status'] === 200) { token
                if (currentUser['data']) {
                    token = currentUser['data'].token
                }
            }
        }
        if (token && token) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },

            });
        }
        return next.handle(request).pipe(retry(2)).
            pipe(tap(event => {
                if (event instanceof HttpResponse) {
                    if (event.body && event.body.error === true && event.body.errorMessage) {
                        throw new Error(event.body.errorMessage);
                    }
                }
            })).pipe(catchError((err) => {
                if (err.status === 400) {
                    this.toastr.errorToastr(err.message, err.status)
                    return
                }
                else if (err.status === 401) {
                    this.toastr.errorToastr(err.message, err.status)
                    return;
                }
                else if (err.status === 500) {
                    this.toastr.errorToastr(err.message, err.status)
                    return;
                } else {
                    this.toastr.errorToastr(err.message, err.status)
                    return;
                }
                return EMPTY;
            }));
    }
}