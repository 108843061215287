import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error404-layout',
  templateUrl: './error404-layout.component.html',
  styleUrls: ['./error404-layout.component.scss']
})
export class Error404LayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
