import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {
    NgbAccordionModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    NgbButtonsModule,
    NgbTabsetModule,
    NgbCollapseModule,
    NgbDateParserFormatter,
    NgbDropdownModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbCarouselModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './NgbDateCustomParserFormatter ';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ng6-toastr-notifications';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AlertModule } from '../dependence/_alert';
import {NgSelectModule} from '@ng-select/ng-select'; 
import {NgxPaginationModule} from 'ngx-pagination';
@NgModule({
    declarations: [
    ],
    imports: [
        FormsModule,
        CommonModule,
        NgbTabsetModule,
        NgbAccordionModule,
        NgbModalModule,
        NgbPaginationModule,
        NgbDatepickerModule,
        NgbButtonsModule,
        NgbCollapseModule,
        ReactiveFormsModule,
        NgbDropdownModule,
        NgbPopoverModule,
        NgxSpinnerModule,
        ToastrModule.forRoot(),
        RichTextEditorAllModule,
        AngularFontAwesomeModule,
        NgbTooltipModule,
        DragDropModule,
        AlertModule,
        Ng2SearchPipeModule,
        NgSelectModule,
        NgxPaginationModule,
        NgbCarouselModule
 
    ],
    exports: [
        FormsModule,
        NgbTabsetModule,
        NgbAccordionModule,
        NgbModalModule,
        NgbPaginationModule,
        NgbDatepickerModule,
        NgbButtonsModule,
        NgbCollapseModule,
        ReactiveFormsModule,
        NgbDropdownModule,
        NgxSpinnerModule,
        ToastrModule,
        RichTextEditorAllModule,
        NgbPopoverModule,
        AngularFontAwesomeModule,
        NgbTooltipModule,
        DragDropModule,
        AlertModule,
        Ng2SearchPipeModule,
        NgSelectModule,
        NgxPaginationModule,
        NgbCarouselModule,
    ], 
    providers: [DatePipe,
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }   ],

})
export class DependenceModule { }
