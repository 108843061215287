
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { NavData } from '../_model/menu';
import { map } from 'rxjs/operators';
import { User } from '../_model/userlogin';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    menuCambio = new Subject<NavData[]>();
    _URLApiBase: string = `${environment.Url}Security/ValidarAccesos`;
    token: string = ""
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem(environment.TOKEN_NAME)));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(user_name: string, user_pwd: string) {
      console.log
        let body = {
            "usuario": encodeURIComponent(user_name),
            "contraseña": encodeURIComponent(user_pwd),
        }
        return this.http.post<any>(`${this._URLApiBase}`, body)

            .pipe(map(user => {
                if (user['status'] === 200) {
                    if (user['data']) {
                        this.token = user['data'].token
                    }
                }
                //almacena detalles de usuario y token jwt en almacenamiento local para mantener al usuario conectado entre actualizaciones de página
                sessionStorage.setItem(environment.TOKEN_NAME, JSON.stringify(user.data.token));
                if (user['data'] && user['status'] === 200)  {
                    this.token = user['data'].token
                    sessionStorage.setItem(environment.idEmpresa, JSON.stringify(user.data.userAccess[0].idEmpresa));
                    this.currentUserSubject.next(user);
                    return user;
                }

            }));
    }
    Logout() {
        let access_token = JSON.parse(sessionStorage.getItem(environment.TOKEN_NAME)).access_token;
        sessionStorage.clear();
        this.router.navigate(['login']);
    }
}




