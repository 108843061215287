export const environment = {
  production: false,
  //Url: "http://190.117.161.223:9079/api/",
  //Url: "https://murat-ti.com:9093/api/",

  //URL_Coombo: 'http://190.117.161.223:9079/api/Murat/',
  //URL_Coombo: 'https://murat-ti.com:9093/api/Murat/',

  Url: "https://maitte-dev.com:9079/api/",
  URL_Coombo: 'https://maitte-dev.com:9079/api/Murat/Services/',
  TOKEN_NAME: 'access_token',
  idEmpresa: 'param',
};

