import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error403-layout',
  templateUrl: './error403-layout.component.html',
  styleUrls: ['./error403-layout.component.scss']
})
export class Error403LayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
